import { useState } from "react";
import { useMutate } from "../lib/use-api";
import { Button } from "./Button";
import { ArrowRightIcon } from "lucide-react";
import { client } from "../tsr/client";

export const AddSite = () => {
  const [url, setUrl] = useState("");
  const { data, mutate, isLoading } = useMutate(client.sites.newSite);
  return (
    <form
      className="flex w-full flex-col gap-2"
      onSubmit={async (e) => {
        e.preventDefault();
        const res = await mutate({ body: { url } });
        if (res.status !== 200) return;

        window.location.href = `/start/${res.body.id}/sources`;
      }}
    >
      {data && data.status !== 200 && <p className="text-red-500">{data.body.message}</p>}
      <input
        className="h-11 w-full rounded-md border border-gray-300 px-4"
        type="text"
        placeholder="Enter your site URL"
        value={url}
        onChange={(e) => setUrl(e.target.value)}
      />
      <Button isLoading={isLoading} className="btn btn-primary">
        Generate Your Search
        <ArrowRightIcon className="h-6 w-6" />
      </Button>
    </form>
  );
};
